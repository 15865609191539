// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  --ion-font-family: "Aventa", arial;

  // Custom variables
  --icon-size: 30px;
  --container-size: 20px;

  --ion-item-border-color: #ffffff;
  --ion-color-white: #ffffff;
  --ion-background-light-gray: #5C6064;

  /** primary **/
  --ion-color-primary: #afe100;
  --ion-color-primary-rgb: 175, 225, 0;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #9ac600;
  --ion-color-primary-tint: #b7e41a;

  /** secondary **/
  --ion-color-secondary: #85bc00;
  --ion-color-secondary-rgb: 133, 188, 0;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #75a500;
  --ion-color-secondary-tint: #91c31a;

  /** tertiary **/
  --ion-color-tertiary: #7757fb;
  --ion-color-tertiary-rgb: 119, 87, 251;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #694ddd;
  --ion-color-tertiary-tint: #8568fb;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #eed000;
  --ion-color-warning-rgb: 238, 208, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d1b700;
  --ion-color-warning-tint: #f0d51a;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** primary-black **/
  --ion-color-primary-black: #0c0c0d;
  --ion-color-primary-black-rgb: 12, 12, 13;
  --ion-color-primary-black-contrast: #ffffff;
  --ion-color-primary-black-contrast-rgb: 255, 255, 255;
  --ion-color-primary-black-shade: #0b0b0b;
  --ion-color-primary-black-tint: #242425;

  /** secondary-black **/
  --ion-color-secondary-black: #1f1f25;
  --ion-color-secondary-black-rgb: 31, 31, 37;
  --ion-color-secondary-black-contrast: #ffffff;
  --ion-color-secondary-black-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-black-shade: #1b1b21;
  --ion-color-secondary-black-tint: #1f1f25;

  /** tertiary-black */
  --ion-color-tertiary-black: #2f2f38;
  --ion-color-tertiary-black-rgb: 47, 47, 56;
  --ion-color-tertiary-black-contrast: #ffffff;
  --ion-color-tertiary-black-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-black-shade: #292931;
  --ion-color-tertiary-black-tint: #44444c;

  /** primary-gray **/
  --ion-color-primary-gray: #858891;
  --ion-color-primary-gray-rgb: 133, 136, 145;
  --ion-color-primary-gray-contrast: #000000;
  --ion-color-primary-gray-contrast-rgb: 0, 0, 0;
  --ion-color-primary-gray-shade: #757880;
  --ion-color-primary-gray-tint: #5e5e5e;

  /** secondary-gray **/
  --ion-color-secondary-gray: #c2c3cc;
  --ion-color-secondary-gray-rgb: 194, 195, 204;
  --ion-color-secondary-gray-contrast: #000000;
  --ion-color-secondary-gray-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-gray-shade: #abacb4;
  --ion-color-secondary-gray-tint: #c8c9d1;
}
