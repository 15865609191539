/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Regular style toast
@import "ngx-toastr/toastr";

@font-face {
  font-family: "Pixer";
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/pixerRegular400.otf");
}

@font-face {
  font-family: "Aventa";
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/EllenLuff - Aventa Variable.ttf");
}

@font-face {
  font-family: "Aventa";
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/aventa-regular-webfont.woff");
}

@font-face {
  font-family: "Aventa";
  font-style: bold;
  font-weight: bold;
  src: url("assets/fonts/aventa-bold-webfont.woff");
}

@font-face {
  font-family: "Saira";
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/SairaSemiCondensed-Regular.ttf");
}

@font-face {
  font-family: "Formula1";
  font-style: normal;
  font-weight: normal;
  src: url("assets/fonts/Formula1-Bold_web_0.ttf");
}

@font-face {
  font-family: "CodeNextTrial";
  font-style: italic;
  font-weight: 600;
  src: url("assets/fonts/CodeNext-Trial-BlackItalic.otf");
}

.swiper-slide {
  width: auto !important;
}

ion-header {
  box-shadow: none;
}

ion-footer {
  box-shadow: none;
}

ion-toolbar {
  // --background: var(--ion-color-primary-black);
  --background: #0C0C0D;
  --padding-start: 1em;
  --padding-end: 1em;
  --padding-top: 1em;
  --padding-bottom: 1em;
  padding-top: 0.5em !important;

  ion-icon {
    font-size: 48px;
    font-weight: 100;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;

    ion-icon {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

ion-icon.back-arrow {
  font-size: 28px;
}

ion-content {
  --ion-background-color: linear-gradient(#0C0C0D 17%, #0C0C0D 100%);
  --ion-text-color: var(--ion-color-light);
  box-shadow: none;
}

ion-button {
  font-size: 16px;
  text-transform: none;
  --box-shadow: none;
  border-radius: 4px;
  --border-radius: 4px;
  height: 50px;
}

ion-button.black-button {
  --background: var(--ion-color-primary-black);
  --color: var(--ion-color-light);
}

.border-width-1 {
  border-width: 1px !important;
}

ion-input {
  font-size: 18px;
  box-shadow: none;
}

ion-card {
  box-shadow: none;
  border-radius: 10px;
}

h1 {
  font-size: 48px;
  font-weight: bold;
}

h2 {
  font-size: 36px;
  font-weight: bold;
}

h3 {
  font-size: 32px;
  font-weight: bold;
}

h4 {
  font-size: 24px;
  font-weight: bold;
}

h5 {
  font-size: 18px;
  font-weight: bold;
}

h6 {
  font-size: 16px;
  font-weight: bold;
}

p {
  font-size: 13px;
  font-weight: 500;
}

ion-item {
  padding-left: 0px;
}

.green-bg {
  --ion-background-color: var(--ion-color-primary);
  --ion-text-color: var(--ion-color-primary-black);
}

.market-bg {
  --ion-background: linear-gradient(#0C0C0D 17%, #0C0C0D 100%);
  --ion-text-color: var(--ion-color-light);
}

.swiper-pagination-bullet-active {
  width: 24px;
  border-radius: 25px;
}

.fab-button {
  border-style: solid;
  border-radius: 100%;
  border-width: 1px;
  --background: var(--ion-color-primary-black);

  ion-icon {
    font-size: 24px;
  }
}

.error-message {
  color: #ff334e;
}

.container {
  margin: 0px;
  padding: 10px var(--container-size);
  ion-col {
    padding: 0px;
  }
}

.button-bar {
  ion-row {
    ion-col {
      vertical-align: middle;
      text-align: center;
    }
  }

  ion-icon {
    font-size: var(--icon-size);
  }
}

.center-row {
  align-items: center;
}

.round-image {
  border-radius: 10px !important;
  overflow: hidden;
}

.va-icon {
  vertical-align: middle;
}

.text {
  &-primary {
    color: var(--ion-color-primary);
  }

  &-danger {
    color: var(--ion-color-danger);
  }
  &-warn {
    color: var(--ion-color-warning);
  }

  &-gray {
    color: var(--ion-color-primary-gray);
  }

  &-white {
    color: var(--ion-color-light);
  }
}

.d-flex {
  display: flex;
}

.flex {
  &-row {
    display: flex;
    flex-direction: row;
  }
  &-col {
    display: flex;
    flex-direction: column;
  }
}

.d-flex-middle {
  display: flex;
  align-items: center;
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.d-block {
  display: block;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.m-0 {
  margin: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-auto {
  margin-right: auto;
}

.p-0 {
  padding: 0;
}

.w-100 {
  width: 100%;
}

.separator-dark {
  background-color: var(--ion-color-primary-black);
}

ion-modal.bg-transparent > .modal-wrapper {
  background: transparent;
}

.striked-card {
  --background: var(--ion-color-secondary-black);
  margin-left: 0;
  margin-right: 0;
}

.row-border-bottom {
  border-bottom: 1px solid var(--ion-color-primary-black);
  padding: 8px 16px;
}

.vertical-allign-middle {
  vertical-align: middle;
}

.pane {
  background-color: var(--ion-color-secondary-black) !important;
}

.pane .box {
  background-color: var(--ion-color-tertiary-black) !important;
  margin: 0 auto;
  border-radius: 13px;
}

.pane .title {
  color: var(
    --ion-card-color,
    var(--ion-item-color, var(--ion-color-step-600, #666666))
  );
}

.white {
  color: #fff;
}

:host {
  .time-body {
    background: var(--ion-color-primary-gray-black);
    color: var(--ion-color-primary-black);
  }
}

ion-searchbar {
  &.market-sc {
    --icon-color: var(--ion-color-light);
    --background: linear-gradient(#0C0C0D 17%, #0C0C0D 100%);
    text-align: left;
    height: 36px;
    margin-bottom: 10px;

    .searchbar-input.sc-ion-searchbar-ios {
      border: 1px solid var(--ion-color-primary-gray-tint) !important;
      font-size: 12px !important;
    }

    .searchbar-search-icon.sc-ion-searchbar-ios {
      font-size: 12px !important;
    }
  }
  &.transparent{
    --icon-color: var(--ion-color-light);
    text-align: left;
    height: 36px;
    margin-bottom: 10px;

    .searchbar-input.sc-ion-searchbar-ios {
      border: 1px solid #F5F5FA !important;
      font-size: 12px !important;
      background: rgba(0, 0, 0, 0) !important;
    }

    .searchbar-search-icon.sc-ion-searchbar-ios {
      font-size: 12px !important;
    }
  }
}

body {
  background-color: var(--ion-color-primary-black);
}

ion-refresher {
  ion-refresher-content {
    --color: #fff !important;

    .refresher-pulling-text,
    .refresher-refreshing-text,
    .refresher-refreshing-icon,
    .refresher-pulling-icon {
      color: #fff !important;
    }
  }
}

.active-tab {
  width: 8px;
  height: 8px;
  background-color: var(--ion-color-primary);
  border-radius: 50%;
  margin: 0 auto;
}

.place-item {
  &-start {
    display: grid;
    place-items: flex-start;
  }

  &-center {
    display: grid;
    place-items: center;
  }

  &-end {
    display: grid;
    place-items: flex-end;
  }
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.email-toast {
  display: flex;
  flex-direction: column;
  gap: 5px;
  strong {
    font-size: 18px;
  }
}

@for $i from 1 through 10 {
  .h-#{$i * 5} {
    height: $i * 5%;
  }
  .h-#{$i * 10} {
    height: $i * 10%;
  }
}

@for $i from 1 through 10 {
  .mt-#{$i * 5} {
    margin-top: $i * 5px;
  }
  .mt-#{$i * 10} {
    margin-top: $i * 10px;
  }
  .mb-#{$i * 5} {
    margin-bottom: $i * 5px;
  }
  .mb-#{$i * 10} {
    margin-bottom: $i * 10px;
  }
  .ml-#{$i * 5} {
    margin-left: $i * 5px;
  }
}

ion-modal::part(handle) {
  width: 120px;
  margin-top: 24px;
  background: white;
}

ion-modal::part(content) {
  --border-radius: 0px;
  --background: transparent;
}

.shepherd-arrow:before {
  background: rgba(47, 47, 56, 1) !important;
}

.shepherd-element {
  width: auto !important;
  background: transparent !important;
}

.shepherd-content {
  background: rgba(47, 47, 56, 1);
  width: 270px;
  max-width: 270px;
}

.shepherd-title {
  color: white !important;
}

.shepherd-text {
  color: white !important;
  font-size: 14px !important;
  font-weight: 100 !important;
  font-family: Aventa !important;
  font-style: normal !important;
  line-height: 22px !important;
  align-self: stretch;
}

.shepherd-header {
  background: rgba(47, 47, 56, 1) !important;
  padding: 16px 16px 0px 16px !important;
  border-top-right-radius: 50px !important;
  border-top-left-radius: 50px !important;
}
.shepherd-footer {
  background: rgba(47, 47, 56, 1) !important;
  padding: 0px 16px 16px 16px !important;
  border-bottom-left-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
}

.shepherd-button-primary {
  background: rgba(161, 225, 0, 1) !important;
  color: rgba(47, 47, 56, 1) !important;
  border-radius: 10px !important;
  padding: 5px 15px !important;
  height: 32px;
  font-size: 14px;
  font-family:
    "Aventa" system-ui,
    -apple-system;
}

@for $i from 1 through 100 {
  .font-size-#{$i} {
    font-size: $i * 1px !important;
  }

  .gap-#{$i} {
    gap: $i * 1px !important;
  }
}

.modal-check-match-conditions {
  --width: 90%;
}

.buy-coin-modal{
  --width: auto;
  --height: auto;
}

.alert{
  --background: var(--ion-color-primary-black);
  --color: var(--ion-color-primary) !important;

  .alert-title.sc-ion-alert-md {
    color: var(--ion-color-primary) !important;
  }

  .alert-head.sc-ion-alert-md+.alert-message.sc-ion-alert-md{
    color: white !important;
  }
}
